$primary-color:#1db954;
$primary-color-hover: #1ed75f;
$primary-color-blue: #193bd4;
$primary-color-orange: #d48319;
$primary-color-cyan:#39c0c3;
$primary-color-cyan-hover:#39a9c3;
$primary-color-rally:#1EB980;


$font-light: #fff;
$font-grey: #9e9e9e;
$font-dark: #000;

$border-grey-light: #3b3b3b;
$border-light-dark: #9e9e9e;

$background-light: #fff;
$background-grey-light: #3b3b3b;

$background-grey: #2d2d2d;
$background-greu-medium: #1c1c1c;
$background-grey-dark: #101010;
$background-grey-dark-light: #141414;
//$background-grey-dark-light:#424250;
$background-dark: #000;

$background-grey-light-hover: #494949;
$background-grey-hover: #3c3c3c;
$background-color-green: #1ed75f;
$danger: #f00;