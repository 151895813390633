@import "../../scss/index.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");

.contenedor {
  width: 100%;
  height: auto;
}

.App-header-landing {
  background-color: black;
  min-height: 100vh;
  //display: flex;
  //flex-direction: column;
  // align-items: center;
  //justify-content: center;
  font-size: calc(12px + 2vmin);
  font-family: "Roboto";
  color: white;
  ////hola abi babis
}

.landing-container {
  margin-top: 1%;
}

.landing-card {
  width: 120%;
  height: 150%;
}

.logotipo {
  position: relative;
  margin-left: 25%;
  left: 45%;
  top: 1%;
  max-width: 40%;
  height: auto;
  margin: auto;
}

p.parr-uno {
  position: relative;
  top: 30px;
  right: 15px;
  font-size: 20px;
  text-align: center;
}

p.parr-dos {
  position: relative;
  top: 30px;
  right: 15px;
  font-size: 20px;
  text-align: center;
  font-family: "Roboto";
}

p.parr-tres {
  position: relative;
  top: 30px;
  right: 15px;
  font-size: 20px;
  text-align: center;
  font-family: "Roboto";
}

p.parr-cuatro {
  position: relative;
  top: 30px;
  right: 15px;
  font-size: 20px;
  text-align: center;
  font-family: "Roboto";
}

p.conocer-titulo {
  position: relative;
  top: 60px;
  color: black;
  font-size: 38px;
  line-height: 45px;
  margin: 35px 0 55px;
  text-align: center;
  font-weight: bold;
  font-family: "Roboto";
}

p.parrsmall {
  position: relative;
  top: 40px;
  color: black;
  font-size: 20px;
  line-height: 24px;
  margin: 30px 0 55px;
  text-align: center;
  font-family: "Roboto";
}
p.detalles {
  color: rgb(143, 0, 0);
  font-size: 20px;
  font: inherit;
  vertical-align: baseline;
  position: relative;
  top: 25px;
  line-height: 24px;
  font-weight: bold;
  text-align: center;
  font-family: "Roboto";
}

img.azul {
  display: inline-block;
  top: 110px;
  padding: 0 40px;
  position: relative;
  // top:700px;
  width: 3000px;
  height: 350px;
}
.der {
  position: relative;
  height: 950px;
  background-color: rgb(143, 0, 0);
  top: -90px;
  left: 1px;
  border-radius: 10px 10px;
  margin: auto;
}

.izq {
  position: relative;
  height: 948px;
  background: #fff;
  left: 1px;
  border-radius: 10px 10px;
}

fieldset {
  position: relative;
  top: -45px;
  border: 0;
}

button.ui.button.landing-envio {
  position: relative;
  /* Adjust these values accordingly */
  top: -35px;
  left: 25%;
  width: 50%;

  background-color: $primary-color-orange;
  color: $font-light;
  font-weight: bold;
  font-size: 16px;
  border-radius: 10px 10px;
  font-family: "Roboto";
  &:hover {
    background-color: #d46a19;
  }
}

// .tres{
//   background-color: #fff;
//   color: #101010;
//   font-size: 80%;
//   width: 100%;
//   margin-left: -10px;
//   border: 0;
//   outline: 0;
//   border-bottom: 1px solid #101010;
// }

ul {
  list-style: none;
}

li::marker {
  list-style: none;
}

li:before {
  position: relative;
  content: "";
  width: 80px;
  height: 7px;
  background: #f5821f;
  position: absolute;
  margin-top: 0px;
  left: 41%;
  border-radius: 5px 5px;
}

li {
  margin: 90px 0;
}

//////////
button.ui.facebook.icon.button {
  position: relative;
  background-color: #a2040a;
  margin-left: 650px;
  top: 540px;
  &:hover {
    background-color: #570205;
  }
}

button.ui.linkedin.circular.icon.button {
  position: relative;
  background-color: #a2040a;
  margin-left: 20px;
  top: 540px;
  &:hover {
    background-color: #570205;
  }
}

button.ui.instagram.circular.icon.button {
  position: relative;
  background-color: #a2040a;
  margin-left: 20px;
  top: 540px;
  &:hover {
    background-color: #570205;
  }
}

h2 {
  position: relative;
  top: 600px;
  left: 640px;
}

input[type="email"],
input[type="password"],
input[type="search"],
input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 5px 15px 0;
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  color: #000;
  font-style: normal;
  height: 35px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #777;
  border-radius: 20px;
  top: 1px;
}

ul.listado-landing {
  position: relative;
  top: 70px;
}

@media screen and (max-width: 1670px) {
  .contenedor {
    width: 100%;
    height: auto;

    p.detalles {
      color: rgb(143, 0, 0);
      font-size: 20px;
      font: inherit;
      vertical-align: baseline;
      position: relative;
      top: 25px;
      line-height: 35px;
      font-weight: bold;
      text-align: center;
      font-family: "Roboto";
    }
    .logotipo {
      position: relative;
      margin-left: 25%;
      left: 45%;
      top: 1%;
      max-width: 40%;
      height: auto;
      margin: auto;
    }
  }
}

@media screen and (max-width: 1030px) {
  .contenedor {
    width: 100%;
    height: auto;

    p.detalles {
      color: rgb(143, 0, 0);
      font-size: 20px;
      font: inherit;
      vertical-align: baseline;
      position: relative;
      top: 25px;
      line-height: 35px;
      font-weight: bold;
      text-align: center;
      font-family: "Roboto";
    }
    .logotipo {
      position: relative;
      margin-left: 25%;
      left: 45%;
      top: 1%;
      max-width: 40%;
      height: auto;
      margin: auto;
    }

    .der {
      position: relative;
      height: 950px;
      background-color: rgb(143, 0, 0);
      top: -90px;
      left: 1px;
      width: 120%;
      border-radius: 10px 10px;
      margin: auto;
    }

    .izq {
      position: relative;
      height: 948px;
      background: #fff;
      left: -20%;
      width: 120%;
      border-radius: 10px 10px;
    }
  }
}

@media screen and (max-width: 800px) {
  .contenedor {
    width: 100%;
    height: auto;

    .logotipo {
      position: relative;
      margin-left: 25%;
      left: 33%;
      top: 1%;
      max-width: 80%;
      height: auto;
      margin: auto;
    }

    .der {
      position: relative;
      height: 800px;
      background-color: rgb(143, 0, 0);
      top: -90px;
      left: 2px;
      width: 90%;
      border-radius: 10px 10px;
      margin: auto;
    }

    .izq {
      position: relative;
      height: 800px;
      background: #fff;
      left: 5%;
      width: 90%;
      border-radius: 10px 10px;
    }

    li:before {
      position: relative;
      content: "";
      width: 80px;
      height: 7px;
      background: #f5821f;
      position: absolute;
      margin-top: 0px;
      left: 45%;
      border-radius: 5px 5px;
    }
  }
}

@media screen and (max-width: 770px) {
  .contenedor {
    width: 100%;
    height: auto;

    .logotipo {
      position: relative;
      margin-left: 25%;
      left: 30%;
      top: 1%;
      max-width: 70%;
      height: auto;
      margin: auto;
    }

    .der {
      position: relative;
      height: 1000px;
      background-color: rgb(143, 0, 0);
      top: -90px;
      left: 0%;
      width: 100%;
      border-radius: 10px 10px;
      margin: auto;
    }

    .izq {
      position: relative;
      height: 1000px;
      background: #fff;
      left: 0%;
      width: 100%;
      border-radius: 10px 10px;
    }

    li:before {
      position: relative;
      content: "";
      width: 80px;
      height: 7px;
      background: #f5821f;
      position: absolute;
      margin-top: 0px;
      left: 45%;
      border-radius: 5px 5px;
    }
  }
}

@media screen and (max-width: 768px) {
  .contenedor {
    width: 100%;
    height: auto;

    .logotipo {
      position: relative;
      margin-left: 25%;
      left: 30%;
      top: 1%;
      max-width: 70%;
      height: auto;
      margin: auto;
    }

    .der {
      position: relative;
      height: 1000px;
      background-color: rgb(143, 0, 0);
      top: -90px;
      left: 0%;
      width: 100%;
      border-radius: 10px 10px;
      margin: auto;
    }

    .izq {
      position: relative;
      height: 1000px;
      background: #fff;
      left: 0%;
      width: 100%;
      border-radius: 10px 10px;
    }

    li:before {
      position: relative;
      content: "";
      width: 80px;
      height: 7px;
      background: #f5821f;
      position: absolute;
      margin-top: 0px;
      left: 35%;
      border-radius: 5px 5px;
    }
  }
}

@media screen and (max-width: 540px) {
  .contenedor {
    width: 100%;
    height: auto;

    .logotipo {
      position: relative;
      margin-left: 25%;
      left: 24%;
      top: 18%;
      max-width: 90%;
      height: auto;
      margin: auto;
    }

    .der {
      position: relative;
      height: 900px;
      background-color: rgb(143, 0, 0);
      top: -90px;
      left: 2px;
      width: 90%;
      border-radius: 10px 10px;
      margin: auto;
    }

    .izq {
      position: relative;
      height: 800px;
      background: #fff;
      left: 5%;
      width: 90%;
      border-radius: 10px 10px;
    }

    li:before {
      position: relative;
      content: "";
      width: 80px;
      height: 7px;
      background: #f5821f;
      position: absolute;
      margin-top: 0px;
      left: 40%;
      border-radius: 5px 5px;
    }
  }
}
@media screen and (max-width: 460px) {
  .contenedor {
    width: 100%;
    height: auto;

    .logotipo {
      position: relative;
      margin-left: 25%;
      left: 24%;
      top: 18%;
      max-width: 90%;
      height: auto;
      margin: auto;
    }

    .der {
      position: relative;
      height: 900px;
      background-color: rgb(143, 0, 0);
      top: -90px;
      left: 2px;
      width: 90%;
      border-radius: 10px 10px;
      margin: auto;
    }

    .izq {
      position: relative;
      height: 800px;
      background: #fff;
      left: 5%;
      width: 90%;
      border-radius: 10px 10px;
    }

    li:before {
      position: relative;
      content: "";
      width: 80px;
      height: 7px;
      background: #f5821f;
      position: absolute;
      margin-top: 0px;
      left: 40%;
      border-radius: 5px 5px;
    }
  }
}

@media screen and (max-width: 380px) {
  .contenedor {
    width: 100%;
    height: auto;

    .logotipo {
      position: relative;
      margin-left: 25%;
      left: 24%;
      top: 18%;
      max-width: 90%;
      height: auto;
      margin: auto;
    }

    .der {
      position: relative;
      height: 900px;
      background-color: rgb(143, 0, 0);
      top: -90px;
      left: 2px;
      width: 90%;
      border-radius: 10px 10px;
      margin: auto;
    }

    .izq {
      position: relative;
      height: 900px;
      background: #fff;
      left: 5%;
      width: 90%;
      border-radius: 10px 10px;
    }

    li:before {
      position: relative;
      content: "";
      width: 80px;
      height: 7px;
      background: #f5821f;
      position: absolute;
      margin-top: 0px;
      left: 37%;
      border-radius: 5px 5px;
    }
  }
}

@media screen and (max-width: 330px) {
  .contenedor {
    width: 100%;
    height: auto;

    .logotipo {
      position: relative;
      margin-left: 25%;
      left: 24%;
      top: 18%;
      max-width: 90%;
      height: auto;
      margin: auto;
    }

    .der {
      position: relative;
      height: 1000px;
      background-color: rgb(143, 0, 0);
      top: -90px;
      left: 2px;
      width: 90%;
      border-radius: 10px 10px;
      margin: auto;
    }

    .izq {
      position: relative;
      height: 1000px;
      background: #fff;
      left: 5%;
      width: 90%;
      border-radius: 10px 10px;
    }

    li:before {
      position: relative;
      content: "";
      width: 80px;
      height: 7px;
      background: #f5821f;
      position: absolute;
      margin-top: 0px;
      left: 40%;
      border-radius: 5px 5px;
    }
  }
}

@media screen and (max-width: 330px) {
  .contenedor {
    width: 100%;
    height: auto;

    .logotipo {
      position: relative;
      margin-left: 25%;
      left: 24%;
      top: 18%;
      max-width: 90%;
      height: auto;
      margin: auto;
    }

    .der {
      position: relative;
      height: 1000px;
      background-color: rgb(143, 0, 0);
      top: -90px;
      left: 2px;
      width: 90%;
      border-radius: 10px 10px;
      margin: auto;
    }

    .izq {
      position: relative;
      height: 1000px;
      background: #fff;
      left: 5%;
      width: 90%;
      border-radius: 10px 10px;
    }

    li:before {
      position: relative;
      content: "";
      width: 80px;
      height: 7px;
      background: #f5821f;
      position: absolute;
      margin-top: 0px;
      left: 36%;
      border-radius: 5px 5px;
    }
  }
}

@media screen and (max-width: 300px) {
  .contenedor {
    width: 100%;
    height: auto;

    .logotipo {
      position: relative;
      margin-left: 25%;
      left: 12%;
      top: 20%;
      max-width: 100%;
      height: auto;
      margin: auto;
    }

    .der {
      position: relative;
      height: 1000px;
      background-color: rgb(143, 0, 0);
      top: -90px;
      left: -4%;
      width: 100%;
      border-radius: 10px 10px;
      margin: auto;
    }

    .izq {
      position: relative;
      height: 1000px;
      background: #fff;
      left: -4%;
      width: 100%;
      border-radius: 10px 10px;
    }

    li:before {
      position: relative;
      content: "";
      width: 80px;
      height: 7px;
      background: #f5821f;
      position: absolute;
      margin-top: 0px;
      left: 40%;
      border-radius: 5px 5px;
    }
  }
}
