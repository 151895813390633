@import "./scss/index.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.Toastify {
  .Toastify__toast-container--top-center {
    bottom: 0;
    left: 10;
    padding: 0;
    margin: 0;
    width: 100%;
     text-align: center;

    .Toastify__toast--success {
      background-color: $primary-color-cyan;

      .Toastify__close-button {
        align-self: center;
      }
    }
    .Toastify__toast--warning {
      background-color: $primary-color-orange;

      .Toastify__close-button {
        align-self: center;
      }
    }

  }
}